import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ImageWithCaption = makeShortcode("ImageWithCaption");
const TableOfContents = makeShortcode("TableOfContents");
const Guideline = makeShortcode("Guideline");
const Do = makeShortcode("Do");
const Dont = makeShortcode("Dont");
const GetInTouch = makeShortcode("GetInTouch");
const FeedbackThumbs = makeShortcode("FeedbackThumbs");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ImageWithCaption imageName="drawer-overview" type="png" alt="A drawer with a heading that reads 'Choose services' sits on a dark transparent veil" mdxType="ImageWithCaption" />
    <TableOfContents title="On this page" mdxType="TableOfContents">
      <div className="table-of-contents">
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#how-to-use-drawers"
            }}>{`How to use drawers`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#anatomy-of-a-drawer"
            }}>{`Anatomy of a drawer`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#behaviour-of-a-drawer"
            }}>{`Behaviour of a drawer`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#usage-guidelines"
            }}>{`Usage guidelines`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#content-guidelines"
            }}>{`Content guidelines`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#accessibility"
            }}>{`Accessibility`}</a></li>
        </ul>
      </div>
    </TableOfContents>
    <hr></hr>
    <h2 {...{
      "id": "how-to-use-drawers",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-to-use-drawers",
        "aria-label": "how to use drawers permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`How to use drawers`}</h2>
    <p>{`Drawers are used to provide contextual information that is relevant to the page content underneath it. They are useful for cross referencing but only intended for a specific action. As drawers are disruptive to the user's experience, they should be used sparingly.`}</p>
    <p><strong parentName="p">{`Use a drawer:`}</strong></p>
    <ul>
      <li parentName="ul">{`Display additional or supplemental information to the user about an element or item.`}</li>
      <li parentName="ul">{`Make simple, contextual tasks available, for example adding or editing items within forms that would create scrolling or other usability issues if presented inline or in a modal.`}</li>
    </ul>
    <p><strong parentName="p">{`Don’t use a drawer:`}</strong></p>
    <ul>
      <li parentName="ul">{`If the additional content is part of a flow, consider loading a new page or grouping content into an accordion instead.`}</li>
      <li parentName="ul">{`If you want the user to focus on only the content in the drawer, we recommend you use a modal for this.`}</li>
      <li parentName="ul">{`When confirming an action, consider using a modal instead.`}</li>
    </ul>
    <hr></hr>
    <h2 {...{
      "id": "anatomy-of-a-drawer",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#anatomy-of-a-drawer",
        "aria-label": "anatomy of a drawer permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Anatomy of a drawer`}</h2>
    <p>{`A drawer can include the following:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Header`}</strong>{`: includes a title, supporting text, leading icon, and a dismiss icon button.`}</li>
      <li parentName="ul"><strong parentName="li">{`Content`}</strong>{`: contains the content and/or controls needed to complete the drawer’s task. It can include text and/or components.`}</li>
      <li parentName="ul"><strong parentName="li">{`Footer`}</strong>{` (`}<em parentName="li">{`optional`}</em>{`): contains the primary actions needed to complete or cancel the drawer task.
Blanket: a tinted transparent overlay that deprioritises the page content underneath the drawer.`}</li>
    </ul>
    <ImageWithCaption imageName="drawer-anatomy" type="png" alt="A drawer separated into three to display the header, content and footer elements that make up the component" mdxType="ImageWithCaption" />
    <hr></hr>
    <h2 {...{
      "id": "behaviour-of-a-drawer",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#behaviour-of-a-drawer",
        "aria-label": "behaviour of a drawer permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Behaviour of a drawer`}</h2>
    <h3>{`Size`}</h3>
    <p>{`The drawer is available in four sizes: small (30%), medium (60%), large (90%), and full (100%). On smaller devices (`}<strong parentName="p">{`<640px/sm breakpoint`}</strong>{`), the drawer becomes fluid and occupies 100% of the viewport.`}</p>
    <ImageWithCaption imageName="drawer-size" type="png" alt="Three examples of the drawer demonstrate the width of a drawer. On mobile, it takes up 100% while the tablet and desktop examples take up 90% of the viewport" mdxType="ImageWithCaption" />
    <h3>{`Position`}</h3>
    <p>{`The drawer is able to slide into view from four different positions: `}<strong parentName="p">{`top`}</strong>{`, `}<strong parentName="p">{`left`}</strong>{`, `}<strong parentName="p">{`bottom`}</strong>{`, and `}<strong parentName="p">{`right`}</strong>{`.`}</p>
    <ImageWithCaption imageName="drawer-position" type="png" alt="Four examples of the drawer demonstrate the element sliding into view from the top, left, bottom and right" mdxType="ImageWithCaption" />
    <h3>{`Dismiss`}</h3>
    <p>{`The drawer is dismissible by using the ‘`}<strong parentName="p">{`X`}</strong>{`’ icon in the drawer header (a child component). Alternatively, the user can click on the blanket to close the drawer. For keyboard users, they will press `}<inlineCode parentName="p">{`Escape`}</inlineCode>{` to close the drawer.`}</p>
    <ImageWithCaption imageName="drawer-dismiss" type="png" alt="A drawer within a desktop viewport is being dismissed by pressing the cross icon in the top right, another cursor is pressing the blanket to indicate an alternative way of dismissing the drawer" mdxType="ImageWithCaption" />
    <hr></hr>
    <h2 {...{
      "id": "usage-guidelines",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#usage-guidelines",
        "aria-label": "usage guidelines permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Usage guidelines`}</h2>
    <h3>{`Avoid overlapping drawers`}</h3>
    <p>{`Drawers are designed to be interruptive, ensuring that the users focus is only on the content within the drawer. Therefore, it's important that only one is used at a time and they are not stacked or nested.`}</p>
    <Guideline mdxType="Guideline">
  <Do center mdxType="Do">
    <ImageWithCaption imageName="drawer-nested-do" type="png" alt="A single drawer is displayed with a dark transparent veil sitting behind it" mdxType="ImageWithCaption" />
  </Do>
  <Dont center mdxType="Dont">
    <ImageWithCaption imageName="drawer-nested-dont" type="png" alt="A collection of drawers are displayed, overlapping one another with a dark transparent veil sitting behind them" mdxType="ImageWithCaption" />
  </Dont>
    </Guideline>
    <hr></hr>
    <h2 {...{
      "id": "content-guidelines",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#content-guidelines",
        "aria-label": "content guidelines permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Content guidelines`}</h2>
    <h3>{`Headings`}</h3>
    <p>{`All drawers must have a descriptive heading that describes the content within the drawer.`}</p>
    <Guideline mdxType="Guideline">
  <Do center mdxType="Do">
    <ImageWithCaption imageName="drawer-heading-do" type="png" alt="A drawer with a heading that reads 'Added to trolley' sits on a dark transparent veil" mdxType="ImageWithCaption" />
  </Do>
  <Dont center mdxType="Dont">
    <ImageWithCaption imageName="drawer-heading-dont" type="png" alt="A drawer with a heading that reads 'More information' sits on a dark transparent veil" mdxType="ImageWithCaption" />
  </Dont>
    </Guideline>
    <h3>{`Actions`}</h3>
    <p>{`Actions are optional within a drawer, but when they’re used they should be easy to understand what will happen if the button is used. `}</p>
    <Guideline mdxType="Guideline">
  <Do center mdxType="Do">
    <ImageWithCaption imageName="drawer-actions-do" type="png" alt="A drawer with two actions that read 'Go to trolley' and 'Continue shopping' sits on a dark transparent veil" mdxType="ImageWithCaption" />
  </Do>
  <Dont center mdxType="Dont">
    <ImageWithCaption imageName="drawer-actions-dont" type="png" alt="A drawer with two actions that read 'Trolley' and 'Cancel' sits on a dark transparent veil" mdxType="ImageWithCaption" />
  </Dont>
    </Guideline>
    <hr></hr>
    <h2 {...{
      "id": "accessibility",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#accessibility",
        "aria-label": "accessibility permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Accessibility`}</h2>
    <h3>{`Aria`}</h3>
    <p>{`Use the `}<strong parentName="p">{`aria-label`}</strong>{` or `}<strong parentName="p">{`aria-labelledby`}</strong>{` prop to add an accessible name to the drawer. This will tell assistive technologies the name of the element given by this label helping users to understand what the element is for and how they can interact with it.`}</p>
    <h3>{`Focus Considerations`}</h3>
    <p>{`When a drawer opens, the drawer container receives focus. The objective of this is “`}<strong parentName="p">{`focus trapping`}</strong>{`”, which allows the user to tab through drawer content without the risk of falling out of it’s tab index.`}</p>
    <h3>{`Keyboard Navigation`}</h3>
    <p>{`Keyboard navigation is important for helping users who do not use a mouse or pointer device. All interactive elements like links, buttons and controls must be able to receive focus and be operable using standard keyboard controls.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`Tab`}</inlineCode>{`: Moves focus to next focusable element inside the drawer. When focus is on the last focusable element in the drawer, moves focus to the first focusable element in the drawer.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Shift`}</inlineCode>{`+`}<inlineCode parentName="li">{`Tab`}</inlineCode>{`: Moves focus to previous focusable element inside the drawer. When focus is on the first focusable element in the drawer, moves focus to the last focusable element in the drawer.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Escape`}</inlineCode>{`: Closes the drawer.`}</li>
    </ul>
    <hr></hr>
    <GetInTouch mdxType="GetInTouch">
  Our team can answer any questions about using drawers or give you a helping
  hand with your next project.
    </GetInTouch>
    <hr></hr>
    <FeedbackThumbs mdxType="FeedbackThumbs" />
    <hr></hr>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      